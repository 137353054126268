import { mobileAdUnitId } from 'mfe-articles-renderer/src/components/MobileAd/MobileAd';

const TestIds = {
    MOBILE_AD_SCRIPT: 'mobile-ad-script',
};

function MobileAdLoader() {
    const adUnit = {
        path: '/34363400/HBR_300x600',
        adSizes: [
            [300, 600],
            [300, 250],
            [600, 1200],
            [600, 500],
        ],
        adTargets: {
            position: '3',
            inventory: 'BTF',
        },
    };

    const scriptContent = `
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(() => {
            window.googletag.defineSlot('${adUnit.path}',  ${JSON.stringify(adUnit.adSizes)}, '${mobileAdUnitId}')
                .addService(googletag.pubads())
                .setTargeting('position', '${adUnit.adTargets.position}')
                .setTargeting('inventory', '${adUnit.adTargets.inventory}')
                .setTargeting('isHidden', window.innerWidth > 767 ? 'true' : 'false')
        })
    `;
    return (
        <script
            data-testid={TestIds.MOBILE_AD_SCRIPT}
            dangerouslySetInnerHTML={{ __html: scriptContent }}
        />
    );
}

export default MobileAdLoader;
export { TestIds as MobileAdLoaderTestIds };
