import UAParser from 'ua-parser-js';

function isTablet({
    innerWidth = null,
    innerHeight = null,
    maxTouchPoints = 0,
    ontouchstart = undefined,
}) {
    const isTouch = ontouchstart || maxTouchPoints >= 0;
    const isTabletSize = innerWidth >= 600 && innerWidth <= 1024;
    const isLargeScreen = innerWidth >= 768 && innerWidth <= 1280;
    const isPortrait = innerWidth < innerHeight;

    return isTouch && isTabletSize && isLargeScreen && isPortrait;
}

function getDeviceType(userAgent, options = {}) {
    const userAgentParser = UAParser(userAgent);
    const device = userAgentParser.device.type;
    const mobile = 'Mobile';
    const desktop = 'Desktop';
    const tablet = 'Tablet';
    let deviceType = desktop;

    if (device === 'mobile') {
        deviceType = mobile;
    } else if (device === 'tablet' || (!device && isTablet(options))) {
        deviceType = tablet;
    }

    return deviceType;
}

export default getDeviceType;
