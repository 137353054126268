import { primaryAdUnitId } from 'components/PrimaryAd/PrimaryAd';
import { rightAdUnitId } from 'components/RightRailAd/RightRailAd';

const adUnits = [
    {
        path: '/34363400/HBR_970x250',
        id: primaryAdUnitId,
        adSizes: [
            [300, 250],
            [600, 500],
            [970, 250],
            [728, 90],
            [1940, 500],
        ],
        adTargets: {
            position: '1',
            inventory: 'ATF',
        },
    },
    {
        path: '/34363400/HBR_300x600',
        id: rightAdUnitId,
        adSizes: [
            [300, 600],
            [300, 250],
            [600, 1200],
            [600, 500],
        ],
        adTargets: {
            position: '2',
            inventory: 'ATF',
        },
    },
    // Unit ids for inline ads.
    {
        path: '/34363400/HBR_970x250',
        id: 'DFP_IC_pos7',
        adSizes: [
            [300, 250],
            [600, 500],
            [970, 250],
            [728, 90],
            [1940, 500],
        ],
        adTargets: {
            position: '7',
            inventory: 'BTF',
        },
    },
    {
        path: '/34363400/HBR_970x250',
        id: 'DFP_IC_pos8',
        adSizes: [
            [300, 250],
            [600, 500],
            [970, 250],
            [728, 90],
            [1940, 500],
        ],
        adTargets: {
            position: '8',
            inventory: 'BTF',
        },
    },
    {
        path: '/34363400/HBR_970x250',
        id: 'DFP_IC_pos9',
        adSizes: [
            [300, 250],
            [600, 500],
            [970, 250],
            [728, 90],
            [1940, 500],
        ],
        adTargets: {
            position: '9',
            inventory: 'BTF',
        },
    },
];

export default adUnits;
